import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
//import BottomDrawer from '../../components/bottomDrawer'

export function BottomDock(props) {
  const links = [
    { url: '/routes', title: 'ROUTES', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    { url: '/destinations', title: 'DESTINATIONS', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    { url: '/discovery-maps', title: 'DISCOVER', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    // { url: '/explore', title: 'EXPLORE', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    // { url: '/map', title: 'MAP', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    { url: '/experiences', title: 'EXPERIENCES', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' },
    { url: '/plan', title: 'PLAN', icon: 'nav-icon-droversway.png', iconHover: 'nav-icon-droversway.png' }
  ];
  
  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  );

  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
    </>
  );
}
