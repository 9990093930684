/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState, useRef, Fragment } from "react";
import { Loading } from "../../../../components";
import env from "../../../../env";
import style from "./atdwPopupPageStyle";
import Slider from "react-slick";
import utils from "../../../../utils";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { AiOutlineMenuUnfold } from "react-icons/ai";

export function AtdwPopupPage({ atdwPopupId, closeSetMenuOpen }) {
  const [atdwData, setAtdwData] = useState(null);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    setAtdwData(null);
    setOpenMap(false);
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/atdw/getproductbyid/" + atdwPopupId,
        { signal: signal }
      );
      const data = await res.json();
      const isAtdwProduct = data.results.IsAtdwProduct;

      if (isAtdwProduct === true) {
        if (data && data.results && data.results.product) {
          const _product = data.results.product;
          const _multimedia = [];
          const _video = [];
          const _features = [];
          const _geoCode = { lat: 0, lng: 0 };
          let _primaryPhone = "";
          let _siteUrl = "";
          let _bookingUrl = "";

          let va = [];
          let _tempDataId;
          for (var n = 0; n < _product.multimedia.length; n++) {
            if (
              _product.multimedia[n].attributeIdMultimediaContent === "IMAGE" &&
              _product.multimedia[n].attributeIdSizeOrientation === "16X9" &&
              parseInt(_product.multimedia[n].width) >= 800
            ) {
              if (!_tempDataId) {
                _tempDataId = _product.multimedia[n].multimediaId;
              }

              if (_tempDataId === _product.multimedia[n].multimediaId) {
                va.push(_product.multimedia[n]);
              } else {
                _multimedia.push(va);
                va = [];
                va.push(_product.multimedia[n]);
                _tempDataId = _product.multimedia[n].multimediaId;
              }
            } else if (
              _product.multimedia[n].attributeIdMultimediaContent === "VIDEO"
            ) {
              _video.push(_product.multimedia[n]);
            }

            if (n === _product.multimedia.length - 1 && va.length > 0) {
              _multimedia.push(va);
            }
          }

          for (var i in _product.addresses) {
            if (_product.addresses[i].attributeIdAddress === "PHYSICAL") {
              _geoCode.lat = Number(_product.addresses[i].geocodeGdaLatitude);
              _geoCode.lng = Number(_product.addresses[i].geocodeGdaLongitude);
              //_address = _product.addresses[i].addressLine1;
              //_postCode = _product.addresses[i].addressPostalCode;
              break;
            }
          }

          _product.communication.forEach((el) => {
            if (el.attributeIdCommunicationDescription === "Primary Phone") {
              _primaryPhone = el.communicationDetail;
            } else if (
              el.attributeIdCommunicationDescription === "URL Enquiries"
            ) {
              _siteUrl = el.communicationDetail;
            } else if (
              el.attributeIdCommunicationDescription === "Booking URL"
            ) {
              _bookingUrl = el.communicationDetail;
            }
          });

          // if (_product.externalSystems.length > 0) {
          //     for (var nn in _product.externalSystems) {
          //         if (_product.externalSystems[nn].externalSystemCode === "TXA_DEFAULT") {
          //             const externalSystemText = _product.externalSystems[nn].externalSystemText;
          //             _bookingUrl = `https://book.txa.com.au/v4/Pages/Availability.aspx?exl_dn=Destination_Riverland_web&exl_psn=${externalSystemText}&exl_ad=2&exl_ch=0&exl_dte=`;
          //             break;
          //         }
          //     }
          // }

          _product.attributes.forEach((el) => {
            if (el.attributeTypeId === "ENTITY FAC") {
              _features.push(el);
            }
            // else if(el.attributeTypeId === "MEMBERSHIP"){
            //     _flag.push(el);
            // }
          });

          setAtdwData({
            multimedia: _multimedia,
            product: _product,
            features: _features,
            geoCode: _geoCode,
            searchTextTitle: "SEARCH " + _product.productCategoryDescription,
            primaryPhone: _primaryPhone,
            siteUrl: _siteUrl,
            bookingUrl: _bookingUrl,
            isAtdwProduct: isAtdwProduct,
            atdwType: _product.productCategoryId,
          });
        } else {
          setAtdwData({
            error: "No product",
          });
        }
      } else {
        if (data && data.results && data.results.product) {
          const _product = data.results.product;
          const _primaryPhone = _product.communication.find(
            (x) => x.attributeIdCommunicationDescription === "Primary Phone"
          );
          const _siteUrl = _product.communication.find(
            (x) => x.attributeIdCommunicationDescription === "URL Enquiries"
          );
          const _bookingUrl = _product.communication.find(
            (x) => x.attributeIdCommunicationDescription === "Booking URL"
          );
          setAtdwData({
            multimedia: _product.atdwImages,
            isAtdwProduct: isAtdwProduct,
            product: _product,
            features: _product.features,
            geoCode: {
              lat: Number(_product.extraGeoCodeLat),
              lng: Number(_product.extraGeoCodeLng),
            },
            atdwType: _product.productType,
            primaryPhone: _primaryPhone && _primaryPhone.communicationDetail,
            siteUrl: _siteUrl && _siteUrl.communicationDetail,
            bookingUrl: _bookingUrl && _bookingUrl.communicationDetail,
          });
        } else {
        }
      }
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [atdwPopupId]);

  if (!atdwData) {
    return <Loading />;
  } else if (atdwData.error) {
    return (
      <div className='atdw__popup__page' css={style.atdw__popup__page}>
        {atdwData.error}
      </div>
    );
  }

  return (
    <Fragment>
      <div
        className='atdw__popup__page__back__to__menu'
        css={style.atdw__popup__page__back__to__menu}
        onClick={closeSetMenuOpen}
      >
        <AiOutlineMenuUnfold />
        <span>Back to menu</span>
      </div>

      <div className='atdw__popup__page' css={style.atdw__popup__page}>
        <AtdwPageBaner
          multimedia={atdwData.multimedia}
          primaryPhone={atdwData.primaryPhone}
          siteUrl={atdwData.siteUrl}
          bookingUrl={atdwData.bookingUrl}
          isAtdwProduct={atdwData.isAtdwProduct}
          setOpenMap={setOpenMap}
        />
        <div
          className='htmlPanel atdw__popup__page__container'
          css={style.atdw__popup__page__container}
        >
          <AtdwPageDetail
            product={atdwData.product}
            isAtdwProduct={atdwData.isAtdwProduct}
          />
          <AtdwContactDetails
            communication={atdwData.product.communication}
            isAtdwProduct={atdwData.isAtdwProduct}
          />
          {atdwData.features && atdwData.features.length > 0 && (
            <AtdwFeatures features={atdwData.features} />
          )}
          {atdwData.product.eventFrequency &&
            atdwData.product.eventFrequency.length > 0 && (
              <AtdwDateTime
                dates={atdwData.product.eventFrequency}
                isAtdwProduct={atdwData.isAtdwProduct}
              />
            )}
          <div id='atdwPageLocation'>
            <AtdwLocation
              geoCode={atdwData.geoCode}
              productName={atdwData.product.productName}
              openMap={openMap}
              setOpenMap={setOpenMap}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function AtdwLocation({ geoCode, productName, openMap, setOpenMap }) {
  //const [open, setOpen] = useState(false);
  const ele = useRef(null);

  useEffect(() => {
    var map, marker;
    map = new window.google.maps.Map(ele.current, {
      center: { lat: geoCode.lat, lng: geoCode.lng },
      zoom: 15,
      //mapId: "77731b8119077e37"
    });

    marker = new window.google.maps.Marker({
      position: { lat: geoCode.lat, lng: geoCode.lng },
      map: map,
      title: productName,
    });

    console.log(marker);

    // eslint-disable-next-line
  }, []);

  return (
    <section>
      <div css={style.expendablePanel} className={openMap ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className='exPanelTitle'
          onClick={(e) => {
            setTimeout(() => {
              document
                .getElementById("atdwPageLocation")
                .scrollIntoView({ behavior: "smooth", block: "center" });
            }, 800);
            setOpenMap(!openMap);
          }}
        >
          <span css={style.expanleTitle}> LOCATION </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className='expendablePanelContent'
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <div style={{ height: "450px" }}>
            <div ref={ele} style={{ height: "100%" }}></div>
          </div>
        </div>
      </div>
    </section>
  );
}

function AtdwDateTime({ dates, isAtdwProduct }) {
  const [open, setOpen] = useState(false);
  const todayDate = new Date();
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className='exPanelTitle'
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> DATES &#38; TIME </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className='expendablePanelContent'
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <div className='row'>
            {dates.map((item, index) => {
              let pDate = new Date(item.frequencyStartDate);
              let monthResult = "";
              let startTime = isAtdwProduct ? item.frequencyStartTime : "";

              console.log("pDate.getMonth", pDate.getMonth());

              switch (pDate.getMonth()) {
                case 0:
                  monthResult = "January";
                  break;
                case 1:
                  monthResult = "February";
                  break;
                case 2:
                  monthResult = "March";
                  break;
                case 3:
                  monthResult = "April";
                  break;
                case 4:
                  monthResult = "May";
                  break;
                case 5:
                  monthResult = "June";
                  break;
                case 6:
                  monthResult = "July";
                  break;
                case 7:
                  monthResult = "August";
                  break;
                case 8:
                  monthResult = "September";
                  break;
                case 9:
                  monthResult = "October";
                  break;
                case 10:
                  monthResult = "November";
                  break;
                case 11:
                  monthResult = "December";
                  break;
                default:
                  monthResult = "";
                  break;
              }

              if (startTime && startTime !== "") {
                const timeList = item.frequencyStartTime.split(":");
                if (parseInt(timeList[0]) > 12) {
                  startTime = timeList[0] - 12 + ":" + timeList[1] + " pm";
                } else {
                  startTime = timeList[0] + ":" + timeList[1] + " am";
                }
              }

              let dateResult =
                pDate.getDate() +
                "th " +
                monthResult +
                " " +
                pDate.getFullYear() +
                (startTime && startTime !== "" ? " " + startTime : "");

              if (pDate >= todayDate) {
                return (
                  <div
                    className='col-6 col-sm-4'
                    key={index}
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {dateResult}
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

function AtdwFeatures({ features }) {
  const [open, setOpen] = useState(false);
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className='exPanelTitle'
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> FEATURES </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className='expendablePanelContent'
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <div className='row'>
            {features.map((item, index) => {
              return (
                <div className='col-12 col-sm-4' key={index}>
                  <FaCircle
                    style={{
                      height: "0.2em",
                      width: "0.2em",
                      verticalAlign: "middle",
                    }}
                  />
                  <span css={style.content}>
                    {" "}
                    {item.attributeIdDescription}{" "}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

function AtdwContactDetails({ communication, isAtdwProduct }) {
  const [open, setOpen] = useState(false);
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className='exPanelTitle'
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> CONTACT DETAILS </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className='expendablePanelContent'
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          {communication.map((item, index) => {
            if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("email")) ||
              (isAtdwProduct === false && item.type === "email")
            ) {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={"mailto:" + item.communicationDetail}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("phone")) ||
              (isAtdwProduct === false && item.type === "tel")
            ) {
              let phoneNumber = item.communicationDetail;
              if (item.communicationDetail && item.communicationDetail !== "") {
                let _phoneNumber =
                  (item.communicationAreaCode
                    ? item.communicationAreaCode
                    : "") + item.communicationDetail.replace(/\s/g, "");
                phoneNumber =
                  "+" + item.communicationIsdCode + _phoneNumber.slice(1);
              }
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={"tel:" + phoneNumber}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("url")) ||
              (isAtdwProduct === false && item.type === "url")
            ) {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={
                      item.communicationDetail.includes("http")
                        ? item.communicationDetail
                        : "https://" + item.communicationDetail
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <span> {item.communicationDetail} </span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}

function AtdwPageDetail({ product, isAtdwProduct }) {
  function GetDes() {
    if (isAtdwProduct) {
      let _texts = product.productDescription.split("\n");
      return _texts.map((text, index) => (
        <p css={style.productDes} key={index} className={`${"des__" + index}`}>
          {text}
        </p>
      ));
    }
    return (
      <div
        css={style.productDes}
        dangerouslySetInnerHTML={{
          __html:
            product.extraDescription ||
            (product.shortDesc && product.shortDesc),
        }}
      ></div>
    );
  }

  return (
    <section>
      <h1 css={style.productName}>{product.productName.toUpperCase()}</h1>
      <GetDes />
      {product.rateFrom &&
        product.rateFrom !== "" &&
        product.rateTo &&
        product.rateTo !== "" && (
          <div css={style.productRate}>
            Rate : {"$" + product.rateFrom} ~ {"$" + product.rateTo}
          </div>
        )}
    </section>
  );
}

function AtdwPageBaner({
  multimedia,
  primaryPhone,
  siteUrl,
  bookingUrl,
  setOpenMap,
  isAtdwProduct,
}) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const _multimedia =
    multimedia.length > 0
      ? multimedia
      : [
          {
            imageUrl:
              "/sites/droversway/media/banners/natural-indulgence/255254.jpg",
          },
        ];

  return (
    <section style={{ overflow: "hidden" }} css={style.bannerSection}>
      <div className='container' css={style.bannerConatiner}>
        <Slider {...settings}>
          {_multimedia.map((item, index) => (
            <div key={index} css={style.bannerItem}>
              {isAtdwProduct === true ? (
                <picture>
                  {item.map((_item, _index) => {
                    return (
                      <source
                        key={_index}
                        crossOrigin='anonymous'
                        srcSet={_item.serverPath}
                        media={_item.width <= 800 ? "(min-width:600px)" : null}
                      />
                    );
                  })}
                  <img src='/assets/logos/logo.png' alt='image2' />
                </picture>
              ) : (
                <div
                  style={{
                    backgroundImage: utils.css.bgUrlStyle(item.imageUrl),
                  }}
                  css={style.backgroundBannerItem}
                ></div>
              )}
            </div>
          ))}
        </Slider>

        <div css={style.barBtnContainer}>
          {primaryPhone && primaryPhone !== "" && (
            <a href={"tel:" + primaryPhone}>
              <button
                css={style.barBtn}
                className='btn btn-primary'
                type='button'
              >
                CONTACT
              </button>
            </a>
          )}

          <button
            css={style.barBtn}
            className='btn btn-primary'
            type='button'
            onClick={() => {
              setTimeout(() => {
                document
                  .getElementById("atdwPageLocation")
                  .scrollIntoView({ behavior: "smooth", block: "center" });
              }, 800);
              setOpenMap(true);
            }}
          >
            LOCATION
          </button>

          {siteUrl && siteUrl !== "" && (
            <a
              href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                css={style.barBtn}
                className='btn btn-primary'
                type='button'
              >
                {" "}
                WEBSITE{" "}
              </button>
            </a>
          )}

          {bookingUrl && bookingUrl !== "" && (
            <a
              href={
                bookingUrl.includes("http")
                  ? bookingUrl
                  : "https://" + bookingUrl
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              <button
                css={style.barBtn}
                className='btn btn-primary'
                type='button'
              >
                {" "}
                BOOKINGS{" "}
              </button>
            </a>
          )}
        </div>
      </div>
      {/* <div css={style.hoverPanel}>
                <div css={style.hoverPanelTop}
                    className={`${(siteUrl && siteUrl !== '') || (bookingUrl && bookingUrl !== '') ? 'two-line' : 'one-line'}`}>
                    <div className={`wave-animation-bottom`} style={{ color: "#f26c33" }}>
                        <svg width="1440px" height="25px" viewBox="0 0 1440 25" preserveAspectRatio="none"><g data-svg-origin="0 0" ><path fill="currentColor" d="M 0 12.5 q 360 -25 720 0 t 720 0 t 720 0 t 720 0 t 720 0 t 720 0 V 0 H 0 V 12.5"></path></g></svg>
                    </div>
                </div>
            </div> */}
    </section>
  );
}
