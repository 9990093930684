import { variables, mq } from '../../../cssInJs';

export default {

    atdwTile : mq({
        flexGrow: '1',
        flexShrink: '0',
        flexBasis: ['100%',null,'33.3%'],
        maxWidth: ['100%',null,'33.3%'],
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingLeft: '0.7rem',
        paddingRight: '0.7rem',

        '&.useSlider' : {
            flexBasis: '100%',
            maxWidth: '100%',
            height: '100%'
        },

        'a' : {
            display:'flex',
            flexDirection:'column',
            height:'100%',
            '&:hover' : {
                textDecoration: 'none',
                '.atdwitembg' : {
                    //transform: 'scale(1.05)'
                },
                '.atdwitembg__container' : {
                    transform: 'scale(1.05)'
                }
            }
        }
    }),

    atdwitembg__container : {
        padding : '.3rem',
        backgroundColor : '#fff',
        borderRadius : '5px',
    //     padding: .3rem;
        transition: 'all .2s'
    // background-color: #fff;
    // z-index: 1;
    // border-radius: 5px;
    },

    bg : {
        paddingTop: '56.25%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize:'cover',
        //boxShadow: '3px 1px 12px rgba(0,0,0,0.7)',
        transition: 'all 0.2s'
    },
    container : {
        position: 'relative',
        overflow: 'hidden',
        flexGrow: '1',
        //backgroundColor: variables.dkBlueColor,  //rgba(255,255,255, 0.6)
        //backgroundColor: 'rgb(19, 39, 52)', //rgba(255, 255, 255, 0.6)
        backgroundColor: 'rgba(255, 255, 255, 0.6)',
        //marginTop: '-20px',
        width: '100%',
        //marginLeft: 'auto',
        //marginRight: 'auto',
        //textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        //boxShadow: '3px 1px 12px rgba(0,0,0,0.7)',
    },
    details : {
        padding: '1rem 0.8rem',
        flex: '1 0 auto',
        '&.more' : {
            paddingTop:'0.5rem'
        }
    },
    pName : {
        fontSize: '1.8rem',
        textTransform: 'uppercase',
        //fontWeight: 700,
        lineHeight: 1,
        //color: variables.orangeColor,
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow:'hidden',
        textOverflow: 'ellipsis',
        display:'-webkit-box',
        fontFamily: variables.familyHeader,
        margin : '0.5rem 0',
        color: '#534741',
        letterSpacing: '3.7px'
    },
    hr : {
        width: '40% !important',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        borderTopColor: variables.orangeColor,
        marginLeft:'auto !important',
        marginRight:'auto !important'
    },
    des :{
        lineHeight: '1.3',
        fontSize:'18px',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow:'hidden',
        textOverflow: 'ellipsis',
        display:'-webkit-box',
        color: '#534741'
    },
    eventDate : {
        fontSize:'0.7rem',
        color: '#534741',
        marginBottom:'0.2rem'
    },
    bottomImg:{
        width: '100%'
    }
}