/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./itineraryStyle";
import {
  Fragment,
  useEffect,
  useState,
  useRef,
  useCallback,
  useLayoutEffect,
} from "react";
import { useRouter, Loading, NotFound, ErrorPopup } from "../../../components";
import env from "../../../env";
//import { AiOutlineMenuUnfold } from "react-icons/ai";
import { MdArrowDropDownCircle } from "react-icons/md";
import { isMobile } from "../../siteUtils";
import { ViewPartial } from "../../../cms/pages/partials/viewPartial";
import { AtdwPopupPage } from "./atdwPopupPage/atdwPopupPage";

export function Itinerary() {
  const slug = useRouter().query.slug;
  const tripPlanIndex = useRouter().query.tripplanindex;
  const [itineraryData, setItineraryData] = useState();
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/itinerary/getitinerary/" + slug,
        { signal: signal }
      );
      const data = await res.json();

      if (data.statusCode && data.statusCode === 500) {
        setNotFound(true);
      } else {
        setItineraryData(data);
      }
    };

    fetchData();

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  if (!itineraryData && notFound === false) {
    return <Loading />;
  } else if (notFound) {
    return <NotFound />;
  }
  return (
    <Fragment>
      <ItineraryLayout
        itineraryData={itineraryData}
        tripPlanIndex={tripPlanIndex}
      />
      <div style={{ display: "none" }}>
        <ViewPartial />
      </div>
    </Fragment>
  );
}

function ItineraryLayout({ itineraryData, tripPlanIndex }) {
  const mapItem = useRef(null);
  const layoutRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [openMapOnMobile, setOpenMapOnMobile] = useState(false);
  const [markersData, setMarkersData] = useState();
  const [markersDataD, setMarkersDataD] = useState();
  const [mapData, setMapData] = useState();
  const [erropMsg, setErropMsg] = useState();
  const [initialCheck, setInitialCheck] = useState(0);
  const tpIndex = parseInt(tripPlanIndex);

  //const tpIndex = 1;
  const [currentSubItem, setCurrentSubItem] = useState(() => {
    if (itineraryData.subItems && itineraryData.subItems.length > 0) {
      return itineraryData.subItems[!isNaN(tpIndex) ? tpIndex : 0];
    }
  });
  const [checkCurrentDayItemOpen, setCheckCurrentDayItemOpen] = useState(() => {
    if (itineraryData.subItems && itineraryData.subItems.length > 0) {
      if (
        !isNaN(tpIndex) &&
        itineraryData.subItems[tpIndex].dayItems &&
        itineraryData.subItems[tpIndex].dayItems.length > 0
      ) {
        return true;
      } else {
        if (
          itineraryData.subItems[0].dayItems &&
          itineraryData.subItems[0].dayItems.length > 0
        ) {
          return true;
        }
      }
    }

    return false;
  });
  const [currentDayItem, setCurrentDayItem] = useState(() => {
    if (itineraryData.subItems && itineraryData.subItems.length > 0) {
      if (
        !isNaN(tpIndex) &&
        itineraryData.subItems[tpIndex].dayItems &&
        itineraryData.subItems[tpIndex].dayItems.length > 0
      ) {
        return itineraryData.subItems[tpIndex].dayItems[0];
      } else {
        if (
          itineraryData.subItems[0].dayItems &&
          itineraryData.subItems[0].dayItems.length > 0
        ) {
          return itineraryData.subItems[0].dayItems[0];
        }
      }
    }
  });
  //const [directionsInfo, setDirectionsInfo] = useState();
  const [atdwPopupTrigger, setAtdwPopupTrigger] = useState(null);
  const [currentMapPinId, setCurrentMapPinId] = useState(null);

  function openMarkerItem(item) {
    setMapOnStartItem(markersData);
    for (let i in markersData) {
      if (markersData[i].id === item.detailItemId) {
        markersData[i].setMap(mapData.map);
        window.google.maps.event.trigger(markersData[i], "mouseover");
        break;
      }
    }
  }

  function closeMarkerItem(item) {
    setMapOnStartItem(markersData);
    if (currentMapPinId !== null) {
      for (let i in markersData) {
        if (markersData[i].id === currentMapPinId) {
          markersData[i].setMap(mapData.map);
          window.google.maps.event.trigger(markersData[i], "mouseover");
          break;
        }
      }
    }
  }

  function setMapOnStartItem(markers) {
    for (let i = 0; i < markers.length; i++) {
      markers[i].setMap(null);
    }
  }

  function openMarkerItemD(index) {
    for (let i in markersDataD) {
      //window.google.maps.event.trigger(markersDataD[i], 'mouseout');
      if (markersDataD[i].id === index + 1) {
        window.google.maps.event.trigger(markersDataD[i], "mouseover");
        break;
      }
    }
  }

  function closeMarkerItemD(index) {
    for (let i in markersDataD) {
      if (markersDataD[i].id === index + 1) {
        window.google.maps.event.trigger(markersDataD[i], "mouseout");
        break;
      }
    }
  }

  function updateCurrentSubItem(subItem) {
    setAtdwPopupTrigger(null);
    setCurrentSubItem(subItem);
  }

  function updateCurrentDayItem(dayItem) {
    if (dayItem === null) {
      setCheckCurrentDayItemOpen(false);
    } else {
      if (
        currentDayItem &&
        dayItem.dayItemId === currentDayItem.dayItemId &&
        checkCurrentDayItemOpen
      ) {
        setCheckCurrentDayItemOpen(false);
      } else {
        setCheckCurrentDayItemOpen(true);
      }

      if (currentDayItem && currentDayItem.dayItemId !== dayItem.dayItemId) {
        setAtdwPopupTrigger(null);
      }
    }

    setCurrentDayItem(dayItem);
    //setOpenMapOnMobile(true);

    if (!isMobile()) {
    } else {
      window.scrollTo(0, 0);
    }
  }

  function openMapOnMobileFunc() {
    setOpenMapOnMobile(!openMapOnMobile);
  }

  function setupAtdwPopupData(atdwData) {
    if (!isMobile()) {
      if (atdwPopupTrigger === atdwData.atdwProductId) {
        setAtdwPopupTrigger(null);
        setCurrentMapPinId(null);
      } else {
        setAtdwPopupTrigger(atdwData.atdwProductId);
        setCurrentMapPinId(atdwData.detailItemId);
        setMapOnStartItem(markersData);

        for (let i in markersData) {
          if (markersData[i].id === atdwData.detailItemId) {
            markersData[i].setMap(mapData.map);
            window.google.maps.event.trigger(markersData[i], "mouseover");
            break;
          }
        }
      }
    } else {
      setAtdwPopupTrigger(atdwData.atdwProductId);

      for (let i in markersData) {
        if (markersData[i].id === atdwData.detailItemId) {
          markersData[i].setMap(mapData.map);
          window.google.maps.event.trigger(markersData[i], "mouseover");
          break;
        }
      }

      setMenuOpen(true);
    }
  }

  function closeSetMenuOpen() {
    setMenuOpen(false);
    setOpenMapOnMobile(false);
    setMapOnStartItem(markersData);
    // for (let i in markersData) {
    //   window.google.maps.event.trigger(markersData[i], "mouseout");
    // }
  }

  useEffect(() => {
    let checkScript = true;
    const script = document.createElement("script");

    if (window["google"] === undefined) {
      checkScript = false;
    } else {
      if (window["google"].maps === undefined) {
        checkScript = true;
      }
    }

    if (!checkScript) {
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY&callback=initMap&loading=async";
      script.async = true;

      window.initMap = function(){
        setupMap();
      }
      
      document.body.appendChild(script);
      // setTimeout(() => {
      //   setupMap();
      // }, 500);
    } else {
      setupMap();
    }

    // function setupMap() {
    //   if (!isMobile()) {
    //     let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
    //     let zoom = 9;
    //     let map = new window.google.maps.Map(mapItem.current, {
    //       center: center,
    //       zoom: zoom,
    //       mapId: "2328f65e96956828",
    //     });
    //     setMapData(map);
    //   } else {
    //     setMapData({
    //       type: "mobile",
    //     });
    //   }
    // }

    function setupMap() {
      let _type = "";
      if (isMobile()) {
        _type = "mobile";
      }

      let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
      let zoom = 9;
      let map = new window.google.maps.Map(mapItem.current, {
        center: center,
        zoom: zoom,
        mapId: "2328f65e96956828",
      });
      setMapData({ map: map, type: _type });
      setInitialCheck(initialCheck + 1);
    }

    const resized = () => {
      let offset = window.innerWidth >= 992 ? 135 : 0;
      //const offset = window.innerWidth >= 844 ? 135 : 0;

      if (
        window.innerWidth >= 768 &&
        window.innerWidth <= 932 &&
        window.innerHeight <= 430
      ) {
        offset = 90;
      }

      const offsetHeight = window.innerHeight - offset;

      layoutRef.current.style.height = offsetHeight + "px";

      //querySelector
      const i_sp = layoutRef.current.querySelector(
        ".itinerary__side__panel.itinerary__container"
      );
      const i_spa = layoutRef.current.querySelector(
        ".itinerary__side__panel.atdw__popup__page__container"
      );
      const i_mp = layoutRef.current.querySelector(".itinerary__main__panel");

      i_sp.style.height = "100%";
      i_mp.style.height = "auto";

      if (i_spa) {
        i_spa.style.height = "auto";
      }

      //document.body.style.overflow = "unset";

      if (isMobile()) {
        const offsetHeightHalf = offsetHeight / 2;
        i_sp.style.height = offsetHeightHalf + "px";
        //i_spa.style.height = (offsetHeightHalf - 50) + 'px';
        if (i_spa) {
          i_spa.style.height = offsetHeightHalf + "px";
        }
        i_mp.style.height = offsetHeightHalf - 50 + "px";
        layoutRef.current.style.height = offsetHeight + "px";
        //document.body.style.overflow = "hidden";
      }
    };
    resized();
    window.addEventListener("resize", resized);

    return function cleanup() {
      if (!checkScript) {
        document.body.removeChild(script);
      }

      if (typeof window !== `undefined`) {
        window.removeEventListener("resize", resized);
      }
    };

    // eslint-disable-next-line
  }, [currentSubItem]);

  useLayoutEffect(() => {
    setErropMsg(null);

    if (initialCheck < 1) {
      return;
    }

    if (!currentDayItem || currentDayItem === null) {
      return;
    }

    if (currentDayItem.routeResult.error) {
      setErropMsg(currentDayItem.routeResult.error.message);
      return;
    }

    if (markersDataD && markersDataD.length > 0) {
      for (let i = 0; i < markersDataD.length; i++) {
        markersDataD[i].setMap(null);
      }
    }

    if (markersData && markersData.length > 0) {
      setMapOnStartItem(markersData);
    }

    const locationItems = currentDayItem.locationItems;
    let markers = [];

    locationItems.forEach((ele) => {
      ele.detailItems.forEach((el) => {
        let geoCode = {
          lat: Number(el.lat),
          lng: Number(el.lng),
        };
        let marker = new window.google.maps.Marker({
          position: { lat: geoCode.lat, lng: geoCode.lng },
          map: mapData.map,
          title: el.title,
          id: el.detailItemId,
          //width : '500px',
          //height: '500px'
          //zIndex: 100,
        });
        let infowindow = new window.google.maps.InfoWindow({
          content:
            '<div class="info__window">' +
            '<div class="info__title">' +
            el.detailItemTitle +
            "</div>" +
            '<div class="info__img">' +
            '<img src="' +
            el.thumbnail +
            '" alt="img" width="100%" >' +
            "</div>" +
            "</div>",
        });

        infowindow.addListener("closeclick", function () {
          setMapOnStartItem(markers);
        });

        marker.addListener("mouseover", function () {
          infowindow.open(mapData.map, marker);
        });
        marker.addListener("mouseout", function () {
          infowindow.close();
        });

        markers.push(marker);
      });
    });

    setMapOnStartItem(markers);

    if (locationItems && locationItems.length > 0) {
      setTimeout(() => {
        calculateAndDisplayRoute(
          currentDayItem,
          mapData.map,
          currentSubItem.theme
        );
      }, 100);
    }

    setMarkersData(markers);

    function calculateAndDisplayRoute(currentDayItem, map, them) {
      let strokeColor = "#e27443";
      let _theme = them || "green";

      switch (_theme.toLowerCase()) {
        case "green":
          strokeColor = "#009245";
          break;
        case "blue":
          strokeColor = "#0071BC";
          break;
        case "orange":
          strokeColor = "#F15A24";
          break;
        default:
          break;
      }

      const mapLineOption = {
        geodesic: true,
        strokeColor: strokeColor,
        strokeOpacity: 0.7,
        strokeWeight: 6,
      };

      const svgVer = "4";
      let _path = [];
      let markers = [];
      let mapCount = 1;
      let mapCount2 = 0;

      currentDayItem.routeResult.routes[0].legs.forEach((leg) => {
        const locationItem = currentDayItem.locationItems[mapCount2];

        _path = _path.concat(leg.decodeRoutes);
        const _mapCount = mapCount;
        const _svgTitle = _mapCount > 8 ? "p" : _mapCount;

        if (!locationItem.hidePin || locationItem.hidePin === false) {
          let marker = new window.google.maps.Marker({
            position: {
              lat: Number(leg.startLocation.latLng.latitude),
              lng: Number(leg.startLocation.latLng.longitude),
            },
            map: map,
            icon:
              "/assets/" +
              (isMobile() ? "pin_small" : "pin") +
              "/" +
              _theme +
              "/" +
              _svgTitle +
              ".svg?v=" +
              svgVer,
            //title: _mapCount,
            id: _mapCount,
          });
          markers.push(marker);
          mapCount++;
        }

        if (
          mapCount2 + 1 ===
          currentDayItem.routeResult.routes[0].legs.length
        ) {
          mapCount2++;
          const locationItem1 = currentDayItem.locationItems[mapCount2];

          if (!locationItem1.hidePin || locationItem1.hidePin === false) {
            //mapCount++;
            const _mapCount1 = mapCount;
            const _svgTitle1 = _mapCount1 > 8 ? "p" : _mapCount1;

            let marker1 = new window.google.maps.Marker({
              position: {
                lat: Number(leg.endLocation.latLng.latitude),
                lng: Number(leg.endLocation.latLng.longitude),
              },
              map: map,
              icon:
                "/assets/" +
                (isMobile() ? "pin_small" : "pin") +
                "/" +
                _theme +
                "/" +
                _svgTitle1 +
                ".svg?v=" +
                svgVer,
              //title: _mapCount,
              id: _mapCount,
            });
            markers.push(marker1);
          }
        }

        // marker.addListener("mouseover", function () {
        //   marker.setIcon("/assets/pin/" + _theme + "/" + _svgTitle + ".svg?v=" + svgVer);
        // });

        // marker.addListener("mouseout", function () {
        //   marker.setIcon("/assets/pin/" + _theme + "/" + _svgTitle + ".svg?v=" + svgVer);
        // });

        mapCount2++;
      });

      const mapPath = new window.google.maps.Polyline({
        ...mapLineOption,
        path: _path,
      });
      mapPath.setMap(map);

      //const locationItems = currentDayItem.locationItems;

      // locationItems.forEach((locationItem) => {
      //   const _mapCount = mapCount;
      //   const _svgTitle = _mapCount > 6 ? "p" : _mapCount;

      //   let marker = new window.google.maps.Marker({
      //     position: {
      //       lat: Number(locationItem.lat),
      //       lng: Number(locationItem.lng),
      //     },
      //     map: map,
      //     icon: "/assets/pin/" + _theme + "/" + _svgTitle + ".svg?v=" + svgVer,
      //     //title: _mapCount,
      //     id: _mapCount,
      //   });

      //   // marker.addListener("mouseover", function () {
      //   //   marker.setIcon("/assets/pin/" + _theme + "/" + _svgTitle + ".svg?v=" + svgVer);
      //   // });

      //   // marker.addListener("mouseout", function () {
      //   //   marker.setIcon("/assets/pin/" + _theme + "/" + _svgTitle + ".svg?v=" + svgVer);
      //   // });

      //   markers.push(marker);
      //   mapCount++;
      // });

      setMarkersDataD(markers);

      var bounds = new window.google.maps.LatLngBounds();
      for (var i = 0; i < _path.length; i++) {
        bounds.extend(_path[i]);
      }
      map.fitBounds(bounds);
    }

    // eslint-disable-next-line
  }, [currentDayItem, initialCheck]);

  return (
    <Fragment>
      {erropMsg && <ErrorPopup title="Loading Error" errors={erropMsg} />}

      {/* {menuOpen && (
        <div
          className="atdw__popup__page__back__to__menu"
          css={style.atdw__popup__page__back__to__menu}
          onClick={closeSetMenuOpen}
        >
          <AiOutlineMenuUnfold />
          <span>Back to menu</span>
        </div>
      )} */}

      <div
        ref={layoutRef}
        className={`itinerary__layout ${menuOpen ? "open" : "close"}`}
        css={style.itinerary__layout}
      >
        <div
          className={`itinerary__side__panel itinerary__container ${
            menuOpen ? "open" : "close"
          }`}
          css={style.itinerary__side__panel}
        >
          <ItineraryView
            itineraryData={itineraryData}
            openMarkerItem={openMarkerItem}
            closeMarkerItem={closeMarkerItem}
            openMarkerItemD={openMarkerItemD}
            closeMarkerItemD={closeMarkerItemD}
            currentSubItem={currentSubItem}
            setCurrentSubItem={setCurrentSubItem}
            currentDayItem={currentDayItem}
            setCurrentDayItem={setCurrentDayItem}
            updateCurrentSubItem={updateCurrentSubItem}
            updateCurrentDayItem={updateCurrentDayItem}
            checkCurrentDayItemOpen={checkCurrentDayItemOpen}
            openMapOnMobile={openMapOnMobile}
            setupAtdwPopupData={setupAtdwPopupData}
            openMapOnMobileFunc={openMapOnMobileFunc}
          />
        </div>

        {!isMobile() && atdwPopupTrigger && atdwPopupTrigger !== "" && (
          <div
            className={`itinerary__side__panel ${menuOpen ? "open" : "close"}`}
            css={style.itinerary__side__panel}
          >
            <AtdwPopupPage atdwPopupId={atdwPopupTrigger} />
          </div>
        )}

        <div
          className={`itinerary__main__panel ${
            openMapOnMobile && checkCurrentDayItemOpen
              ? "openMapOnMobile__open"
              : "openMapOnMobile__close"
          } ${
            atdwPopupTrigger && atdwPopupTrigger !== ""
              ? "atdw__popup__open"
              : "atdw__popup__open__close"
          }`}
          css={style.itinerary__main__panel}
        >
          {/* <div
            className="atdw__popup__page__back__to__menu2"
            css={style.atdw__popup__page__back__to__menu2}
            onClick={closeSetMenuOpen}
          >
            <AiOutlineMenuUnfold />
            <span>Back to menu</span>
          </div> */}

          <div
            ref={mapItem}
            style={{ height: "100%" }}
            className="map__item"
          ></div>
        </div>

        {isMobile() && (
          <div
            className={`itinerary__side__panel atdw__popup__page__container ${
              menuOpen ? "open" : "close"
            }`}
            css={style.itinerary__side__panel}
          >
            {atdwPopupTrigger && atdwPopupTrigger !== "" && (
              <AtdwPopupPage
                atdwPopupId={atdwPopupTrigger}
                closeSetMenuOpen={closeSetMenuOpen}
              />
            )}
          </div>
        )}
      </div>
      <div
        className="itinerary__dumy__background"
        css={style.itinerary__dumy__background}
      ></div>
      {
        <div
          className={`itinerary__dumy__text ${
            mapData && mapData.type && mapData.type === "mobile"
              ? "map__off"
              : "map__on"
          }`}
          css={style.itinerary__dumy__text}
        >
          <button
            className="btn btn-primary"
            type="button"
            onClick={() => {
              window.location.reload();
            }}
          >
            {" "}
            Please refresh page{" "}
          </button>
        </div>
      }
    </Fragment>
  );
}

function ItineraryView({
  itineraryData,
  openMarkerItem,
  closeMarkerItem,
  currentSubItem,
  setupAtdwPopupData,
  currentDayItem,
  updateCurrentSubItem,
  updateCurrentDayItem,
  checkCurrentDayItemOpen,
  openMarkerItemD,
  closeMarkerItemD,
  openMapOnMobileFunc,
}) {
  const title = itineraryData.mainItemTitle;
  const des = itineraryData.mainItemDes;
  const subItems = itineraryData.subItems;
  const theme = currentSubItem.theme || "green";
  return (
    <div
      id="itinerary__view__item__container"
      className="itinerary__view__item__container"
      css={style.itinerary__view__item__container}
    >
      <div
        className="itinerary__view__item__title"
        css={style.itinerary__view__item__title}
      >
        {title}
      </div>

      <div
        className="itinerary__view__item__logo"
        css={style.itinerary__view__item__logo}
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
      >
        <img
          src="/assets/logos/logo.png"
          width="100%"
          alt="img"
          style={{ maxWidth: "120px", margin: "auto", display: "block" }}
        />
      </div>

      <div
        className="itinerary__view__item__des"
        css={style.itinerary__view__item__des}
        dangerouslySetInnerHTML={{ __html: des }}
      ></div>

      {/* <hr className="hr__style" css={style.itinerary__hr__style} /> */}

      <div className="itinerary__sub__items" css={style.itinerary__sub__items}>
        {subItems.map((item, index) => (
          <div
            className={`itinerary__sub__item ${
              item.theme && item.theme !== ""
                ? item.theme.toLowerCase()
                : "green"
            } ${
              currentSubItem.subItemId === item.subItemId
                ? "selected"
                : "unselected"
            }`}
            key={index}
          >
            <div
              className={`itinerary__sub__item__inner ${
                item.theme && item.theme !== ""
                  ? item.theme.toLowerCase()
                  : "green"
              }`}
              onClick={() => {
                updateCurrentSubItem(subItems[index]);
                setTimeout(() => {
                  updateCurrentDayItem(
                    subItems[index].dayItems &&
                      subItems[index].dayItems.length > 0
                      ? subItems[index].dayItems[0]
                      : null
                  );
                }, 500);
                updateCurrentDayItem(null);
              }}
            >
              <div className="itinerary__sub__item__inner__inner">
                {item.subItemTitle}
              </div>
            </div>
          </div>
        ))}
      </div>
      <DayItems
        dayItems={currentSubItem.dayItems}
        updateCurrentDayItem={updateCurrentDayItem}
        currentDayItem={currentDayItem}
        openMarkerItem={openMarkerItem}
        closeMarkerItem={closeMarkerItem}
        checkCurrentDayItemOpen={checkCurrentDayItemOpen}
        openMarkerItemD={openMarkerItemD}
        closeMarkerItemD={closeMarkerItemD}
        setupAtdwPopupData={setupAtdwPopupData}
        theme={theme}
        openMapOnMobileFunc={openMapOnMobileFunc}
      />
    </div>
  );
}

function DayItems({
  dayItems,
  updateCurrentDayItem,
  currentDayItem,
  openMarkerItem,
  closeMarkerItem,
  openMarkerItemD,
  checkCurrentDayItemOpen,
  closeMarkerItemD,
  setupAtdwPopupData,
  theme,
  openMapOnMobileFunc,
}) {
  return (
    <div
      className="itinerary__day__container"
      css={style.itinerary__day__container}
    >
      {dayItems && dayItems.length > 0 ? (
        <div>
          {dayItems.map((item, index) => (
            <div key={index}>
              <div
                className={`itinerary__day__item__title ${theme.toLowerCase()}`}
                css={style.itinerary__day__item__title}
                onClick={() => {
                  updateCurrentDayItem(item);
                }}
              >
                {item.dayItemCustomTitle && item.dayItemCustomTitle !== ""
                  ? item.dayItemCustomTitle
                  : item.dayItemTitle.replace(" Days", "").replace(" Day", "") +
                    " Stop"}
                <div className="itinerary__day__item__title__svg">
                  {" "}
                  <MdArrowDropDownCircle />{" "}
                </div>
              </div>
              {currentDayItem &&
                currentDayItem.dayItemId === item.dayItemId &&
                checkCurrentDayItemOpen && (
                  <Fragment>
                    <ItineraryDes
                      dayItemDes={item.dayItemDes}
                      openMapOnMobileFunc={openMapOnMobileFunc}
                    />
                    <LocationItems
                      locationItems={item.locationItems}
                      openMarkerItem={openMarkerItem}
                      closeMarkerItem={closeMarkerItem}
                      openMarkerItemD={openMarkerItemD}
                      closeMarkerItemD={closeMarkerItemD}
                      setupAtdwPopupData={setupAtdwPopupData}
                      dayItemTitle={item.dayItemTitle}
                      theme={theme}
                    />
                  </Fragment>
                )}
            </div>
          ))}
        </div>
      ) : (
        <div> No Iitems </div>
      )}
    </div>
  );
}

function ItineraryDes({ dayItemDes, openMapOnMobileFunc }) {
  return (
    <Fragment>
      <div
        className="itinerary__day__item__des"
        css={style.itinerary__day__item__des}
        dangerouslySetInnerHTML={{ __html: dayItemDes }}
      ></div>
    </Fragment>
  );
}

function LocationItems({
  locationItems,
  openMarkerItem,
  closeMarkerItem,
  openMarkerItemD,
  closeMarkerItemD,
  setupAtdwPopupData,
  dayItemTitle,
  theme,
}) {
  //console.log("dayItemTitle", dayItemTitle, locationItems);
  const [loItems, setLoItems] = useState();

  function onEnterDest(index) {
    openMarkerItemD(index);
  }

  function onLeaveDest(index) {
    closeMarkerItemD(index);
  }

  const getTitle = useCallback((index) => {
    const list = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
    ];
    return list[index - 1];
  }, []);

  useEffect(() => {
    const _locationItems = [];
    let count = 1;
    locationItems.forEach((locationItem) => {
      if (!locationItem.hidePin || locationItem.hidePin === false) {
        locationItem = { ...locationItem, seq: count };
        _locationItems.push(locationItem);
        count++;
      }
    });

    setLoItems(_locationItems);
    // eslint-disable-next-line
  }, []);

  if (!loItems) {
    return <Loading />;
  }

  return (
    <div>
      {loItems.map((item, index) => (
        <div key={index}>
          <div
            className={`itinerary__item__view ${theme.toLowerCase()} ${
              loItems.length === 1 ? "one__day__trip" : "none__one__day__trip"
            }`}
            css={style.itinerary__item__view}
          >
            <div
              className="itinerary__item__view__wrapper lo_dd"
              css={style.itinerary__item__view__wrapper}
              onMouseEnter={() => {
                onEnterDest(index);
              }}
              onMouseLeave={() => {
                onLeaveDest(index);
              }}
            >
              <div
                className="itinerary__item__view__index"
                css={style.itinerary__item__view__index}
              >
                <div
                  className={`itinerary__view__index__item ${theme.toLowerCase()}`}
                  css={style.itinerary__view__index__item}
                >
                  <div className="itinerary__view__index__item__inner">
                    {loItems.length === 1 ? "A" : getTitle(item.seq)}
                  </div>
                </div>
              </div>
              <div
                className="itinerary__item__view__detail"
                css={style.itinerary__item__view__detail}
              >
                <div className="itinerary__item__view__detail__title">
                  <span className="itinerary__item__view__detail__inner__title">
                    {item.destinationTitle &&
                      item.destinationTitle !== "" &&
                      item.destinationTitle}
                  </span>
                </div>
                <div
                  className="itinerary__item__view__detail__des"
                  css={style.itinerary__item__view__detail__des}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                ></div>
              </div>
            </div>
            {item.detailItems.map((itemN, index) => (
              <ItineraryItemDetailView
                key={index}
                index={index}
                item={itemN}
                openMarkerItem={openMarkerItem}
                closeMarkerItem={closeMarkerItem}
                openMarkerItemD={openMarkerItemD}
                setupAtdwPopupData={setupAtdwPopupData}
                theme={theme}
              />
            ))}
          </div>
          {(dayItemTitle === "1 Day" || dayItemTitle === "1") &&
            loItems.length === 1 &&
            item.endDestination &&
            item.endDestination !== "" && (
              <div
                className="itinerary__item__view end__destination"
                css={style.itinerary__item__view}
              >
                <div
                  className="itinerary__item__view__wrapper lo_dd"
                  css={style.itinerary__item__view__wrapper}
                  onMouseEnter={() => {
                    onEnterDest(index + 1);
                  }}
                  onMouseLeave={() => {
                    onLeaveDest(index + 1);
                  }}
                >
                  <div
                    className="itinerary__item__view__index"
                    css={style.itinerary__item__view__index}
                  >
                    <div
                      className="itinerary__view__index__item"
                      css={style.itinerary__view__index__item}
                    >
                      <div className="itinerary__view__index__item__inner">
                        B
                      </div>
                    </div>
                  </div>
                  <div
                    className="itinerary__item__view__detail"
                    css={style.itinerary__item__view__detail}
                  >
                    <div className="itinerary__item__view__detail__title">
                      {" "}
                      End{" "}
                    </div>
                    <div
                      className="itinerary__item__view__detail__des"
                      css={style.itinerary__item__view__detail__des}
                      dangerouslySetInnerHTML={{ __html: item.endDescription }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
        </div>
      ))}
    </div>
  );
}

function ItineraryItemDetailView({
  item,
  openMarkerItem,
  closeMarkerItem,
  setupAtdwPopupData,
  theme,
}) {
  function onEnter() {
    openMarkerItem(item);
  }

  function onLeave() {
    closeMarkerItem(item);
  }

  function onAtdwPopupData() {
    console.log("item ----- ", item);

    setupAtdwPopupData(item);
  }

  //isMobile
  return (
    <div
      className="itinerary__item__view__wrapper"
      css={style.itinerary__item__view__wrapper}
    >
      <div
        className="itinerary__item__view__index"
        css={style.itinerary__item__view__index}
      >
        <div
          className="itinerary__view__index__item__small"
          css={style.itinerary__view__index__item__small}
        >
          <div className="itinerary__view__index__item__inner__small"></div>
        </div>
      </div>
      {isMobile() ? (
        <div
          className="itinerary__item__view__detail__small"
          css={style.itinerary__item__view__detail__small}
          onClick={onAtdwPopupData}
        >
          <div
            className={`itinerary__item__view__detail__title atdw ${theme.toLowerCase()}`}
          >
            {" "}
            {item.detailItemTitle}{" "}
          </div>
          {/* <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div> */}
        </div>
      ) : (
        <div
          className="itinerary__item__view__detail__small"
          css={style.itinerary__item__view__detail__small}
          onMouseEnter={onEnter}
          onMouseLeave={onLeave}
          onClick={onAtdwPopupData}
        >
          <div
            className={`itinerary__item__view__detail__title atdw ${theme.toLowerCase()}`}
          >
            {" "}
            {item.detailItemTitle}{" "}
          </div>
          {/* <div className='itinerary__item__view__detail__des' css={style.itinerary__item__view__detail__des}>
                            {item.detailItemDes}
                        </div> */}
        </div>
      )}
    </div>
  );
}
