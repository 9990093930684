//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq, variables } from '../../cssInJs'

export default {
     pageTiles: css(mq({
          display: 'flex',
          justifyContent: 'flex-start',
          flexWrap: 'wrap',
          margin: '2rem 0px',
          marginLeft: ['0px', null, null, '-40px', '-44px'],
          marginRight: ['0px', null, null, '-40px', '-44px'],
          marginTop: ['0px', null, null, '2rem'],
          '.pageTile__eyre:nth-of-type(odd) .pageTile__eyre__wrapper': {
               float: 'right',
               '.flip__bg__c': mq({
                    order: ['0', '0', '2'],
                    '.pageTile__eyre__bg': mq({
                         transform: ['unset', null, 'translate(-10%,-7%) rotate(4deg)']
                    })
               }),
               '.pageTile__eyre__content__details': mq({
                    order: '1',
                    paddingLeft: '2rem',
                    paddingRight: ['2rem', null, '3rem']
               }),
               '.pageTile__eyre__content:hover .pageTile__eyre__bg': mq({
                    transform: ['unset', null, 'translate(-10%,-7%) rotate(4deg) scale(1.06)']
               })
          }
     })),
     pageTile__tile: css(mq({
          flex: '0 0 100%',
          maxWidth: '100%',
          padding: '10px',
          zIndex: 1
     })),

     pageTile__wrapper: css(mq({
          width: ['100%', null, '90%', '80%'],
          float: 'left'
     })),
     tile__content: css(mq({
          // display: 'flex',
          // flexDirection: 'row',
          // justifyContent: 'space-between',
          // width: '100%',
          // height: '100%',
          // cursor: 'pointer',
          // //position: 'relative',
          // flexWrap: 'wrap',
          // paddingTop:['0px',null,'1.5rem'],
          '&:hover': {
               textDecoration: 'none',
               //   '& .pageTile__eyre__content__details' : {
               //       backgroundColor : 'rgb(48,88,140,0.9)',
               //       color: 'white',
               //   }
               '& .pageTile__eyre__bg': {
                    //transform : 'unset !important'
                    transform: ['unset', null, 'translate(10%,-7%) rotate(-4deg) scale(1.06)']
               }
          }
     })),
     tile__flip__wrapper: css({
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          height: '100%',
          cursor: 'pointer',
          position: 'relative',
          zIndex: '-1',
          flexWrap: 'wrap',
          paddingTop: '1.5rem',
          '&.first__item': mq({
               paddingTop: ['0px', null, '1.5rem']
          })
     }),
     tile__bg: css(mq({
          height: '0',
          paddingTop: ['70%', '45%', '50%'],
          backgroundPosition: '50%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          //backgroundColor:'red',
          border: '6px solid white',
          borderRadius: '5px',
          boxShadow: '3px 5px 10px rgba(0,0,0,0.3)',
          transition: 'all 0.5s ease-in-out',
          transform: ['unset', null, 'translate(10%,-7%) rotate(-4deg)']
     })),
     tile__bg__c: css(mq({
          flexGrow: '1',
          flexShrink: '1',
          flexBasis: ['300px'],
          zIndex: '9'
     })),

     tile__details: css(mq({
          overflow: 'hidden',
          padding: '1rem 2rem',
          paddingLeft: ['2rem', null, '3.4rem'],
          //textAlign: 'center',
          width: '100%',
          bottom: '0',
          backgroundColor: 'rgba(255,255,255, 0.6)',
          //flex: '1 1 300px',
          flexGrow: '1',
          flexShrink: '1',
          flexBasis: ['300px'],
          transition: 'all .2s ease-in-out',
          marginTop: ['0.2rem', null, '0px'],
          '& .pageTile__eyre__container': {
               position: 'relative',
               top: '44%',
               transform: 'translateY(-50%)',

               '& .pageTile__eyre__title': mq({
                    fontSize: ['2rem', null, null, null, '2.3rem'],
                    //textTransform: 'uppercase',
                    //fontWeight: '700',
                    lineHeight: '1',
                    margin: '0',
                    paddingTop: '.5rem',
                    paddingBottom: '.5rem',
                    color: variables.colorDarkBrown,
                    letterSpacing: '3.7px',
                    fontFamily: 'balboa-condensed'
               }),
               '& .pageTile__eyre__desc': {
                    lineHeight: '1.3',
                    fontSize: '.8rem',
                    //color: '#3d3d3d',
                    minHeight: '70px',
                    color: variables.colorDarkBrown,
                    fontWeight: '400'
               }
          }
     }))
}