
import { renderRule as atdw_featured_search } from './atdw-featured-search/render';
import { renderRule as atdw_map } from './atdw-map/render';
import { renderRule as atdw_map_cellardoor } from './atdw-map-cellardoor/render';
import { renderRule as atdw_map_town } from './atdw-map-town/render';
import { renderRule as atdw_search } from './atdw-search/render';
import { renderRule as atdwPageTiles } from './atdwPageTiles/render';
import { renderRule as code_instagram } from './code-instagram/render';
import { renderRule as code_subscribe } from './code-subscribe/render';
import { renderRule as html } from './html/render';
import { renderRule as imagePanel } from './imagePanel/render';
import { renderRule as pageTiles } from './pageTiles/render';
import { renderRule as panel } from './panel/render';
import { renderRule as photoGallery } from './photoGallery/render';
import { renderRule as sharingIcons } from './sharingIcons/render';
import { renderRule as videoPanel } from './videoPanel/render';
export const items = [
  atdw_featured_search,
  atdw_map,
  atdw_map_cellardoor,
  atdw_map_town,
  atdw_search,
  atdwPageTiles,
  code_instagram,
  code_subscribe,
  html,
  imagePanel,
  pageTiles,
  panel,
  photoGallery,
  sharingIcons,
  videoPanel,
]