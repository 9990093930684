import { mq, variables } from "../../../../cssInJs"; //mqMax,

export default {
  atdw__popup__page__back__to__menu: mq({
    //marginBottom : '0.5rem',
    fontWeight: "400",
    display: ["block", null, "none"],
    position: "sticky",
    top: "-1px",
    backgroundColor: "white",
    zIndex: 3,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.25rem",
    paddingTop: "0.25rem",
    width: "100%",
    //paddingTop : '10px',
    //paddingBottom: '5px',
    //marginTop : '-15px',
    borderBottom: "1px solid",
    "svg": {
      width: "20px",
      height: "20px",
    },
    "span": {
      display: "inline-block",
      marginLeft: "0.5rem",
      verticalAlign: "middle",
    },
  }),
  atdw__popup__page: mq({
    padding: "1rem",
    paddingBottom: "5rem",
    //height : '100%',
    //overflowY: 'auto',
    // paddingBottom: ['3rem',null,'1rem'],
    // paddingTop: ['3rem',null,'1rem']
  }),

  bannerSection: mq({
    //marginTop : ['2rem',null,'0px']
  }),

  bannerConatiner: mq({
    margin: "auto",
    //marginTop: '1.5rem',
    maxWidth: "960px",
    paddingRight: "0px",
    paddingLeft: "0px",
  }),
  bannerItem: mq({
    //border : '1px solid red',
    "img": mq({
      width: "100%",
    }),
  }),
  backgroundBannerItem: mq({
    paddingTop: "56.25%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
  }),
  barBtnContainer: mq({
    display: "flex",
    marginLeft: "0rem",
    marginRight: "0rem",
    marginTop: "0.3rem",
    flexWrap: "wrap",
    justifyContent: ["unset", null, "unset"],
    "a": {
      lineHeight: "0",
    },
  }),
  barBtn: mq({
    padding: ["0.3rem 10px", null, "0.3rem 10px"],
    paddingTop: ["7px !important", null, "9px !important"],
    lineHeight: "10px",
    borderRadius: "13px",
    color: variables.orangeColor,
    backgroundColor: variables.sandCtoneColor,
    marginLeft: "0.3rem",
    marginRight: "0.3rem",
    fontSize: "0.7rem",
    marginTop: "0.2rem",
    marginBottom: "0.2rem",
    width: ["unset", "unset"],
    "&:first-of-type": {
      marginLeft: "0rem",
    },
    // flex: ['1 0 32%',null,'unset'],
    // maxWidth:['32%',null,'unset']
  }),

  atdw__popup__page__container: {},

  productName: mq({
    color: variables.orangeColor,
    lineHeight: "1.1",
    fontSize: "2rem",
    marginBottom: "0rem",
    marginTop: "1rem",
    letterSpacing: "1.7px",
    //paddingLeft: '0.5rem',
    //paddingRight: '0.5rem'
  }),

  productDes: mq({
    "&.des__0": {
      fontWeight: 600,
    },
    fontSize: "0.8rem",
    marginBottom: "0",
    marginTop: "0.5rem",
  }),

  productRate: {
    fontWeight: 600,
    marginTop: "1.5rem",
    //marginBottom: '1rem'
  },

  expendablePanel: {
    marginTop: "1rem",
    fontSize: "0.8rem",
    "&.open": {
      ".exPanelTitle svg": {
        transform: "translateY(-50%) rotate(180deg)",
      },
      ".expendablePanelContent": {
        opacity: "1",
        //height: '100%'
        height: "auto",
      },
    },
  },
  expendablePanelTitle: {
    borderBottom: `1px solid #534841`,
    cursor: "pointer",
    position: "relative",
    "svg": {
      position: "absolute",
      right: "0px",
      top: "50%",
      transform: "translateY(-50%)",
      transition: "all 0.2s ease-in-out",
    },
  },
  expendablePanelContent: {
    transition: "opacity 1s ease-out !important",
    opacity: "0",
    height: "0",
    overflow: "hidden",
  },
  expanleTitle: {
    fontWeight: 600,
  },
  content: {
    fontSize: "0.8rem",
  },
};
