/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment, useRef, useEffect, useState } from "react";
import { Loading } from "../../../../components";
//import { useHistory } from "react-router-dom";
import style from "./atdwCellarDoorMapStyle";
import utils from "utils";
import env from "../../../../env";
import { MdArrowDropDownCircle } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
import { AtdwGallery } from "../../../atdw/atdwGallery/atdwGallery";

export function AtdwCellarDoorMap(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "atdw-map-cellardoor",
    item.cssClass || item.anchorName || ""
  );
  const mapItem = useRef(null);

  const [atdwSlug, setAtdwSlug] = useState(() => {
    let _hash = window.location.hash;
    return _hash && _hash !== "" ? _hash.trim().substring(1) : null;
  });

  const [atdwData, setAtdwData] = useState({ errors: "" });
  const [checkWheretoStart, setCheckWheretoStart] = useState("onloading");

  function setupAtdwData(el) {
    setCheckWheretoStart("button");
    setAtdwSlug(el.slug);
  }

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let checkScript = true;
    const script = document.createElement("script");

    const fetchData = async () => {
      const res = await fetch(env.apiBase + "/api/atdw/getmapproducts", {
        method: "post",
        signal: signal,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          //category : mapState.currentAtdwType,
          //mapArea : mapArea,
          fromType: "atdwmapcellardoor",
        }),
      });

      const data = await res.json();

      if (window["google"] === undefined) {
        checkScript = false;
      } else {
        if (window["google"].maps === undefined) {
          checkScript = true;
        }
      }

      if (!checkScript) {
        script.src =
          "https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY&callback=initMap&loading=async";
        script.async = true;

        window.initMap = function () {
          setupMap();
        };
        document.body.appendChild(script);
        
      } else {
        setupMap();
      }

      function setupMap() {
        const items = data.results.productResult.products;
        let center = { lat: -34.17547561936846, lng: 140.02757156230592 };
        let zoom = 9;
        //let mapPath = null;
        //const mapLineOption = {geodesic: true, strokeColor: "#d44a33", strokeOpacity: 1.0, strokeWeight: 2};

        var map = new window.google.maps.Map(mapItem.current, {
          center: center,
          zoom: zoom,
          mapId: "77731b8119077e37",
        });

        // if(mapPath){
        //     mapPath.setMap(map);
        // }

        let _markers = [];

        items.forEach((el) => {
          let geoCode = {
            lat: Number(el.additionalData.googlemap.geocodeGdaLatitude),
            lng: Number(el.additionalData.googlemap.geocodeGdaLongitude),
          };
          var marker = new window.google.maps.Marker({
            position: { lat: geoCode.lat, lng: geoCode.lng },
            map: map,
            title: el.productName,
            //icon : {fillColor : '#000'}
            //icon: "/assets/pin/pin-hover-1.svg?v=1"
          });

          if (atdwSlug !== null && atdwSlug === el.slug) {
            marker.setIcon("/assets/pin/orange-map.svg?v=1");
          }

          var infowindow = new window.google.maps.InfoWindow({
            //content : _el.shortDesc
            content:
              '<div class="info__window">' +
              '<div class="info__title">' +
              el.productName +
              "</div>" +
              '<div class="info__town">' +
              el.town +
              "</div>" +
              '<div class="info__town">' +
              el.address +
              " " +
              el.town +
              "</div>" +
              '<p class="info__desc">' +
              el.shortDesc +
              "</p>" +
              "</div>",
          });

          var isMobile = false;
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            isMobile = true;
          }

          marker.addListener("mouseover", function () {
            if (!isMobile) {
              infowindow.open(map, marker);
            }
          });
          marker.addListener("mouseout", function () {
            if (!isMobile) {
              infowindow.close();
            }
          });

          marker.addListener("click", function () {
            // if(isMobile) {
            //     setPopupData({
            //         productName : el.productName,
            //         address : el.address,
            //         town : el.town,
            //         desc : el.shortDesc,
            //         slug : el.slug
            //     });
            //     setOpenPopup(true);
            // }
            // else{
            //     history.push('/atdwproduct/' + el.slug);
            // }
            for (let i in _markers) {
              _markers[i].setIcon();
            }
            marker.setIcon("/assets/pin/orange-map.svg?v=1");

            setupAtdwData(el);
          });
          _markers.push(marker);
        });
      }
    };

    fetchData();
    return function cleanup() {
      abortController.abort();
      if (!checkScript) {
        document.body.removeChild(script);
      }
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (atdwSlug === null) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setAtdwData(null);

    let timer = null;
    fetch(env.apiBase + "/api/atdw/getproduct/" + atdwSlug, { signal: signal })
      .then((res) => res.json())
      .then((res) => {
        const data = res;

        if (data.errors && data.errors.length > 0) {
          //setErrors(data.errors[0].errorMessage);
          timer = setTimeout(() => {
            setAtdwData({
              errors: data.errors[0].errorMessage,
            });
          }, 500);
          return;
        }

        const isAtdwProduct = data.results.isAtdwProduct;

        if (isAtdwProduct === true) {
          if (data && data.results && data.results.product) {
            console.log("_features ----> 2");

            const _product = data.results.product;
            const _multimedia = [];
            const _video = [];
            const _features = [];
            const _geoCode = { lat: 0, lng: 0 };
            let _primaryPhone = "";
            let _siteUrl = "";
            let _bookingUrl = "";

            let va = [];
            let _tempDataId;
            for (var n = 0; n < _product.multimedia.length; n++) {
              if (
                _product.multimedia[n].attributeIdMultimediaContent ===
                  "IMAGE" &&
                _product.multimedia[n].attributeIdSizeOrientation === "16X9" &&
                parseInt(_product.multimedia[n].width) >= 800
              ) {
                if (!_tempDataId) {
                  _tempDataId = _product.multimedia[n].multimediaId;
                }

                if (_tempDataId === _product.multimedia[n].multimediaId) {
                  va.push(_product.multimedia[n]);
                } else {
                  _multimedia.push(va);
                  va = [];
                  va.push(_product.multimedia[n]);
                  _tempDataId = _product.multimedia[n].multimediaId;
                }
              } else if (
                _product.multimedia[n].attributeIdMultimediaContent === "VIDEO"
              ) {
                _video.push(_product.multimedia[n]);
              }

              if (n === _product.multimedia.length - 1 && va.length > 0) {
                _multimedia.push(va);
              }
            }

            for (var i in _product.addresses) {
              if (_product.addresses[i].attributeIdAddress === "PHYSICAL") {
                _geoCode.lat = Number(_product.addresses[i].geocodeGdaLatitude);
                _geoCode.lng = Number(
                  _product.addresses[i].geocodeGdaLongitude
                );
                //_address = _product.addresses[i].addressLine1;
                //_postCode = _product.addresses[i].addressPostalCode;
                break;
              }
            }

            _product.communication.forEach((el) => {
              if (el.attributeIdCommunicationDescription === "Primary Phone") {
                _primaryPhone = el.communicationDetail;
              } else if (
                el.attributeIdCommunicationDescription === "URL Enquiries"
              ) {
                _siteUrl = el.communicationDetail;
              } else if (
                el.attributeIdCommunicationDescription === "Booking URL"
              ) {
                _bookingUrl = el.communicationDetail;
              }
            });

            // if (_product.externalSystems.length > 0) {
            //     for (var nn in _product.externalSystems) {
            //         if (_product.externalSystems[nn].externalSystemCode === "TXA_DEFAULT") {
            //             const externalSystemText = _product.externalSystems[nn].externalSystemText;
            //             _bookingUrl = `https://book.txa.com.au/v4/Pages/Availability.aspx?exl_dn=Destination_Riverland_web&exl_psn=${externalSystemText}&exl_ad=2&exl_ch=0&exl_dte=`;
            //             break;
            //         }
            //     }
            // }

            _product.attributes.forEach((el) => {
              if (el.attributeTypeId === "ENTITY FAC") {
                _features.push(el);
              }
              // else if(el.attributeTypeId === "MEMBERSHIP"){
              //     _flag.push(el);
              // }
            });

            timer = setTimeout(() => {
              setAtdwData({
                multimedia: _multimedia,
                product: _product,
                features: _features,
                geoCode: _geoCode,
                searchTextTitle:
                  "SEARCH " + _product.productCategoryDescription,
                primaryPhone: _primaryPhone,
                siteUrl: _siteUrl,
                bookingUrl: _bookingUrl,
                isAtdwProduct: isAtdwProduct,
                atdwType: _product.productCategoryId,
              });
              if (checkWheretoStart === "button") {
                utils.ui.scrollTo("atdw__cellar__door__data");
              }
            }, 500);
          } else {
          }
        } else {
          if (data && data.results && data.results.product) {
            const _product = data.results.product;
            const _primaryPhone = _product.communication.find(
              (x) => x.attributeIdCommunicationDescription === "Primary Phone"
            );
            const _siteUrl = _product.communication.find(
              (x) => x.attributeIdCommunicationDescription === "URL Enquiries"
            );
            const _bookingUrl = _product.communication.find(
              (x) => x.attributeIdCommunicationDescription === "Booking URL"
            );

            timer = setTimeout(() => {
              setAtdwData({
                multimedia: _product.atdwImages,
                isAtdwProduct: isAtdwProduct,
                product: _product,
                features: _product.features, //(_product.features && _product.features.length > 0) ? _product.features.length : []
                geoCode: {
                  lat: Number(_product.extraGeoCodeLat),
                  lng: Number(_product.extraGeoCodeLng),
                },
                atdwType: _product.productType,
                primaryPhone:
                  _primaryPhone && _primaryPhone.communicationDetail,
                siteUrl: _siteUrl && _siteUrl.communicationDetail,
                bookingUrl: _bookingUrl && _bookingUrl.communicationDetail,
              });
              if (checkWheretoStart === "button") {
                utils.ui.scrollTo("atdw__cellar__door__data");
              }
            }, 500);
          } else {
          }
        }
      })
      .catch((error) => {
        console.log("error :: ", error);
      });

    return function cleanup() {
      abortController.abort();
      if (timer) clearTimeout(timer);
    };

    // eslint-disable-next-line
  }, [atdwSlug]);

  return (
    <Fragment>
      <div
        css={style.atdwMap}
        className={cssClass}
        data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
        id={item.anchorName}
      >
        <div style={{ height: "450px" }}>
          <div ref={mapItem} style={{ height: "100%" }}></div>
        </div>
      </div>
      {atdwData === null && <Loading />}
      <div
        id="atdw__cellar__door__data"
        css={style.atdw__cellar__door__data}
        className={`atdw__cellar__door__data ${
          atdwData === null ? "not__expand" : "expand"
        }`}
      >
        {atdwData !== null && atdwData.product && (
          <Fragment>
            <div
              className="container"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <AtdwPageBaner
                primaryPhone={atdwData.primaryPhone}
                siteUrl={atdwData.siteUrl}
                bookingUrl={atdwData.bookingUrl}
                isAtdwProduct={atdwData.isAtdwProduct}
              />
              <AtdwPageDetail
                product={atdwData.product}
                isAtdwProduct={atdwData.isAtdwProduct}
              />
              <AtdwContactDetails
                communication={atdwData.product.communication}
                isAtdwProduct={atdwData.isAtdwProduct}
              />
              <AtdwFeatures features={atdwData.features} />
              {atdwData.product.eventFrequency &&
                atdwData.product.eventFrequency.length > 0 && (
                  <AtdwDateTime
                    dates={atdwData.product.eventFrequency}
                    isAtdwProduct={atdwData.isAtdwProduct}
                  />
                )}
              <AtdwMultimedia
                multimedia={atdwData.multimedia}
                isAtdwProduct={atdwData.isAtdwProduct}
              />
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

function AtdwPageBaner({ primaryPhone, siteUrl, bookingUrl }) {
  return (
    <div css={style.barBtnContainer}>
      {primaryPhone && primaryPhone !== "" && (
        <a href={"tel:" + primaryPhone}>
          <button css={style.barBtn} className="btn btn-primary" type="button">
            CONTACT
          </button>
        </a>
      )}

      {siteUrl && siteUrl !== "" && (
        <a
          href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button css={style.barBtn} className="btn btn-primary" type="button">
            {" "}
            WEBSITE{" "}
          </button>
        </a>
      )}

      {bookingUrl && bookingUrl !== "" && (
        <a
          href={
            bookingUrl.includes("http") ? bookingUrl : "https://" + bookingUrl
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <button css={style.barBtn} className="btn btn-primary" type="button">
            {" "}
            BOOKINGS{" "}
          </button>
        </a>
      )}
    </div>
  );
}

function AtdwPageDetail({ product, isAtdwProduct }) {
  function GetDes() {
    if (isAtdwProduct) {
      let _texts = product.productDescription.split("\n");
      return _texts.map((text, index) => (
        <p css={style.productDes} key={index} className={`${"des__" + index}`}>
          {text}
        </p>
      ));
    }
    return (
      <div dangerouslySetInnerHTML={{ __html: product.extraDescription }}></div>
    );
  }

  return (
    <section>
      <h1 css={style.productName}>{product.productName.toUpperCase()}</h1>
      <GetDes />
      {product.rateFrom &&
        product.rateFrom !== "" &&
        product.rateTo &&
        product.rateTo !== "" && (
          <div css={style.productRate}>
            Rate : {"$" + product.rateFrom} ~ {"$" + product.rateTo}
          </div>
        )}
    </section>
  );
}

function AtdwContactDetails({ communication, isAtdwProduct }) {
  const [open, setOpen] = useState(false);
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className="exPanelTitle"
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> CONTACT DETAILS </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className="expendablePanelContent"
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          {communication.map((item, index) => {
            if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("email")) ||
              (isAtdwProduct === false && item.type === "email")
            ) {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={"mailto:" + item.communicationDetail}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("phone")) ||
              (isAtdwProduct === false && item.type === "tel")
            ) {
              let phoneNumber = item.communicationDetail;
              if (item.communicationDetail && item.communicationDetail !== "") {
                let _phoneNumber =
                  (item.communicationAreaCode
                    ? item.communicationAreaCode
                    : "") + item.communicationDetail.replace(/\s/g, "");
                phoneNumber =
                  "+" + item.communicationIsdCode + _phoneNumber.slice(1);
              }
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={"tel:" + phoneNumber}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else if (
              (isAtdwProduct === true &&
                item.attributeIdCommunicationDescription
                  .toLowerCase()
                  .includes("url")) ||
              (isAtdwProduct === false && item.type === "url")
            ) {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <a
                    href={
                      item.communicationDetail.includes("http")
                        ? item.communicationDetail
                        : "https://" + item.communicationDetail
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    {item.communicationDetail}
                  </a>
                </div>
              );
            } else {
              return (
                <div key={index}>
                  <span>
                    {" "}
                    {item.attributeIdCommunicationDescription + " : "}{" "}
                  </span>
                  <span> {item.communicationDetail} </span>
                </div>
              );
            }
          })}
        </div>
      </div>
    </section>
  );
}

function AtdwFeatures({ features }) {
  const [open, setOpen] = useState(false);
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className="exPanelTitle"
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> FEATURES </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className="expendablePanelContent"
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <div className="row">
            {features.map((item, index) => {
              return (
                <div className="col-12 col-sm-4" key={index}>
                  <FaCircle
                    style={{
                      height: "0.2em",
                      width: "0.2em",
                      verticalAlign: "middle",
                    }}
                  />
                  <span css={style.content}>
                    {" "}
                    {item.attributeIdDescription}{" "}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

function AtdwDateTime({ dates, isAtdwProduct }) {
  const [open, setOpen] = useState(false);
  const todayDate = new Date();
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className="exPanelTitle"
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> DATES &#38; TIME </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className="expendablePanelContent"
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <div className="row">
            {dates.map((item, index) => {
              let pDate = new Date(item.frequencyStartDate);
              let monthResult = "";
              let startTime = isAtdwProduct ? item.frequencyStartTime : "";
              switch (pDate.getMonth()) {
                case 0:
                  monthResult = "January";
                  break;
                case 1:
                  monthResult = "February";
                  break;
                case 2:
                  monthResult = "March";
                  break;
                case 3:
                  monthResult = "April";
                  break;
                case 4:
                  monthResult = "May";
                  break;
                case 5:
                  monthResult = "June";
                  break;
                case 6:
                  monthResult = "July";
                  break;
                case 7:
                  monthResult = "August";
                  break;
                case 8:
                  monthResult = "September";
                  break;
                case 9:
                  monthResult = "October";
                  break;
                case 10:
                  monthResult = "November";
                  break;
                case 11:
                  monthResult = "December";
                  break;
                default:
                  monthResult = "";
                  break;
              }

              if (startTime && startTime !== "") {
                const timeList = item.frequencyStartTime.split(":");
                if (parseInt(timeList[0]) > 12) {
                  startTime = timeList[0] - 12 + ":" + timeList[1] + " pm";
                } else {
                  startTime = timeList[0] + ":" + timeList[1] + " am";
                }
              }

              let dateResult =
                pDate.getDate() +
                "th " +
                monthResult +
                " " +
                pDate.getFullYear() +
                (startTime && startTime !== "" ? " " + startTime : "");

              if (pDate >= todayDate) {
                return (
                  <div
                    className="col-6 col-sm-4"
                    key={index}
                    style={{ fontFamily: "sans-serif" }}
                  >
                    {
                      // (('' + pDate.getDate()).length < 2 ? '0' + pDate.getDate() : '' + pDate.getDate()) + '-' +
                      // (('' + (pDate.getMonth() + 1)).length < 2 ? '0' + (pDate.getMonth() + 1) : '' + (pDate.getMonth() + 1)) + '-' +
                      // (isAtdwProduct ? pDate.getFullYear() + ' : ' + item.frequencyStartTime : pDate.getFullYear())
                      dateResult
                    }
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </section>
  );
}

function AtdwMultimedia({ multimedia, isAtdwProduct }) {
  const [open, setOpen] = useState(false);
  return (
    <section>
      <div css={style.expendablePanel} className={open ? "open" : "close"}>
        <div
          css={style.expendablePanelTitle}
          className="exPanelTitle"
          onClick={(e) => {
            setOpen(!open);
          }}
        >
          <span css={style.expanleTitle}> PHOTO GALLERY &#38; VIDEO </span>
          <MdArrowDropDownCircle />
        </div>
        <div
          css={style.expendablePanelContent}
          className="expendablePanelContent"
        >
          <div style={{ marginTop: "0.5rem" }}></div>
          <AtdwGallery images={multimedia} isAtdwProduct={isAtdwProduct} />
        </div>
      </div>
    </section>
  );
}
