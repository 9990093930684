//import { padding } from 'polished';
import { mq, variables } from "../../../cssInJs"; //mqMax,

export default {
  atdw__popup__page__back__to__menu: mq({
    //marginBottom : '0.5rem',
    fontWeight: "400",
    display: ["block", null, "none"],
    position: "fixed",
    top: "5%",
    backgroundColor: "white",
    zIndex: 9,
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0px",
    paddingTop: "0.7rem",
    width: "100%",
    //paddingTop : '10px',
    //paddingBottom: '5px',
    //marginTop : '-15px',
    borderBottom: "1px solid",
    "svg": {
      width: "20px",
      height: "20px",
    },
    "span": {
      display: "inline-block",
      marginLeft: "0.5rem",
      verticalAlign: "middle",
    },
  }),

  atdw__popup__page__back__to__menu2: mq({
    padding: "0.25rem",
    display: ["block", null, "none"],
    "svg": {
      width: "20px",
      height: "20px",
      marginTop: "-4px",
    },
  }),

  itinerary__dumy__background: {
    width: "100%",
    height: "100%",
    backgroundColor: "#EAE3DC",
    //backgroundColor: 'blue',
    position: "fixed",
    top: "0",
    zIndex: "-1",
    //display: 'none'
  },

  itinerary__dumy__text: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 3,
    display: "none",
    "&.map__off": mq({
      display: ["none", null, "block"],
    }),
  },

  itinerary__page: {
    marginTop: "3rem",
  },
  itinerary__container: mq({
    border: "1px solid red",
    //maxHeight: ['760px'],
    //height: ['760px'],
  }),
  itinerary__menu__icon: mq({
    position: "fixed",
    top: "2px",
    //left: '1rem',
    right: "3rem",
    zIndex: "999",
    padding: "0.5rem",
    borderRadius: "50%",
    borderColor: "rgba(255, 255, 255, 0.08)",
    display: ["black", null, "none"],
    "svg": {
      fontSize: "1.5rem",
      color: "white",
      cursor: "pointer",
    },
  }),
  itinerary__layout: mq({
    display: "flex",
    flexDirection: "row",
    marginTop: ["1px", null, "null"],
    flexWrap: "wrap",
    position: "relative",
    alignContent: ["flex-start", null, "normal"],
    // 'div.gm-style' : {
    // }
  }),
  itinerary__side___close__panel: mq({
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "none",
    zIndex: 2,
    "&.open": mq({
      display: ["block", null, "none"],
    }),
  }),
  itinerary__side__panel: mq({
    flex: ["1 0 100%", null, "1 0 25%"],
    maxWidth: ["100%", null, "25%"],
    height: "100%",
    overflow: "auto",
    zIndex: 3,
    transition: "all 0.3s ease-in-out",
    backgroundColor: "#EAE3DC",
    //backgroundColor: 'red',
    width: "100%",
    "&.itinerary__container": mq({
      order: [2, null, 0],
      paddingBottom: ["5rem", null, "0"],
      "&.open": {
        //transform: 'translateX(0)'
        display: "none",
      },
      "&.close": {
        //transform: 'translateX(-100%)'
        display: "block",
      },
    }),
    // [mqMax.md]: {
    //     '&.open': {
    //         transform: 'translateX(0)'
    //     },
    //     '&.close': {
    //         transform: 'translateX(-100%)'
    //     }
    // },
    "&.atdw__popup__page__container": mq({
      //position: ['absolute', null, 'static'],
      //backgroundColor : '#fdf8e6',
      //overflow: 'hidden',
      //height : '100px',
      position: "relative",
      order: [1, null, 0],
      "&.open": {
        //transform: 'translateX(0)'
        //display: 'none'
        display: "block",
      },
      "&.close": {
        //transform: 'translateX(-100%)'
        //display: 'block'
        display: "none",
      },
    }),
  }),
  itinerary__main__panel: mq({
    flex: ["1 0 100%", null, "1 0 75%"],
    maxWidth: ["100%", null, "75%"],
    //display: ['none', null, 'block'],
    display: ["block", null, "block"],
    zIndex: ["3", null, "auto"],
    transition: "all 0.3s ease-in-out",
    //transform:['translateX(-100%)',null,'none'],
    //paddingTop : ['2.2rem',null,'0'],
    backgroundColor: "white",
    height: "auto",
    "&.atdw__popup__open": mq({
      flex: ["1 0 100%", null, "1 0 50%"],
      maxWidth: ["100%", null, "50%"],
    }),
    "&.openMapOnMobile__open": mq({
      //transform:['translateX(0)',null,'none'],
    }),
    ".gm-ui-hover-effect": {
      //display: 'none !important'
    },
    ".info__window": mq({
      maxWidth: ['100px',null,'210px'] ,
      //maxHeight: ['200px',null,'none'] ,
      //height : ['200px',null,'auto'],
      ".info__title": {
        fontSize: "16px",
        fontWeight: "600",
        marginBottom: "3px",
        color: variables.orangeColor,
        //fontFamily: variables.familyHeader,
        textTransform: "uppercase",
      },
      ".info__img": {
        display: ["none", null, "block"],
        //maxWidth: ["100px", null, "210px"],
        margin: "auto",
      },
    }),
  }),
  itinerary__view__item__container: {
    padding: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  // itinerary__view__item__title: {
  //     fontWeight: 800,
  //     textAlign: 'center',
  //     color: variables.orangeColor
  // },
  itinerary__view__item__title: mq({
    fontWeight: 100,
    textAlign: ["left", null, "center"],
    fontSize: ["2.2rem", null, "2.2rem"],
    color: variables.orangeColor,
    fontFamily: variables.familyHeader,
    marginTop: ["0.8rem", null, "0"],
    letterSpacing: "2.1px",
  }),
  itinerary__view__item__logo: mq({
    display: ["none", null, "block"],
  }),
  itinerary__view__item__des: mq({
    //fontWeight: 800
    marginTop: "1rem",
    fontSize: ["1rem", null, "17px"],
    "p": {
      marginBottom: "0.5rem",
    },
  }),
  itinerary__day__item__des: mq({
    //fontWeight: 800
    marginTop: "1rem",
    fontSize: ["1rem", null, "0.7rem"],
    "p": {
      marginBottom: "0.5rem",
    },
  }),

  itinerary__day__item__map: mq({
    marginTop: "1rem",
    display: ["block", null, "none"],
  }),

  itinerary__hr__style: {
    borderTop: "0.5px solid #ebebeb",
  },

  itinerary__sub__items: {
    display: "flex",
    ".itinerary__sub__item": {
      flex: "1 0 33.3%",
      maxWidth: "33.3%",
      paddingLeft: "0.2rem",
      paddingRight: "0.2rem",
      "&.selected": {
        pointerEvents: "none",
        ".itinerary__sub__item__inner.green": {
          background: "#035E2C",
        },
        ".itinerary__sub__item__inner.blue": {
          background: "#085884",
        },
        ".itinerary__sub__item__inner.orange": {
          background: "#BC421D",
        },
        ".itinerary__sub__item__inner__inner": {
          //color: 'black'
        },
      },
    },
    ".itinerary__sub__item__inner": {
      background: "#f0582B",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&.green": {
        background: "#009245",
        "&:hover": {
          background: "#035E2C",
        },
      },
      "&.blue": {
        background: "#0071BC",
        "&:hover": {
          background: "#085884",
        },
      },
      "&.orange": {
        background: "#F15A24",
        "&:hover": {
          background: "#BC421D",
        },
      },
      ".itinerary__sub__item__inner__inner": mq({
        lineHeight: "1.1",
        padding: "0.3rem 0.2rem",
        textAlign: "center",
        color: "white",
        fontWeight: "600",
        fontSize: ["1rem", null, null, null, "0.8rem"],
        wordBreak: "break-word",
      }),
    },
  },

  itinerary__view__item__detail: {
    position: "relative",
  },

  itinerary__day__container: {
    marginTop: "1rem",
  },

  itinerary__day__item__title: {
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    padding: "0.3rem 0.1rem",
    //color: '#f0582B',
    fontWeight: 600,
    position: "relative",
    cursor: "pointer",
    "&.green": {
      color: "#009245",
    },
    "&.blue": {
      color: "#0071BC",
    },
    "&.orange": {
      color: "#F15A24",
    },
    ".itinerary__day__item__title__svg": {
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
    },
  },

  itinerary__item__view: {
    // display: 'flex',
    // flexDirection: 'row',
    // flexWrap: 'wrap',
    position: "relative",
    marginTop: "0.3rem",
    marginBottom: "0.3rem",
    paddingBottom: "0.5rem",
    "&::before": {
      position: "absolute",
      content: "''",
      bottom: "5px",
      top: "12px",
      left: "12px",
      borderRight: "1px solid #f0582B",
    },
    "&.green::before": {
      borderColor: "#009245",
    },
    "&.blue::before": {
      borderColor: "#0071BC",
    },
    "&.orange::before": {
      borderColor: "#F15A24",
    },
    "&::after": {
      position: "absolute",
      content: "''",
      bottom: "0px",
      left: "8px",
      borderRight: "4.5px solid transparent",
      borderLeft: "4.5px solid transparent",
      borderTop: "9px solid #f0582B",
    },
    "&.green::after": {
      borderColor: "#009245",
    },
    "&.blue::after": {
      borderColor: "#0071BC",
    },
    "&.orange::after": {
      borderColor: "#F15A24",
    },
    "&:last-child, &.one__day__trip": {
      "&::after": {
        position: "absolute",
        content: "''",
        bottom: "0px",
        left: "8px",
        borderRight: "4.5px solid #f0582B",
        borderLeft: "4.5px solid #f0582B",
        borderTop: "9px solid #f0582B",
      },
      "&.green::after": {
        borderColor: "#009245",
      },
      "&.blue::after": {
        borderColor: "#0071BC",
      },
      "&.orange::after": {
        borderColor: "#F15A24",
      },
    },
    "&.end__destination": {
      "&::after, &::before": {
        display: "none",
      },
    },
  },
  itinerary__item__view__wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    "&:hover": {
      "&:not(.lo_dd)": {
        ".itinerary__item__view__detail__title": {
          color: "#ea4335",
          "&.atdw.green": {
            color: "#035E2C",
          },
          "&.atdw.orange": {
            color: "#BC421D",
          },
          "&.atdw.blue": {
            color: "#085884",
          },
        },
      },
    },
    "&.lo_dd": {
      cursor: "pointer",
      // '&:hover' : {
      //     '.itinerary__item__view__detail__title' : {
      //         color : '#ea4335'
      //     }
      // },
    },
  },

  itinerary__item__view__index: mq({
    // flex: '1 0 10%',
    // maxWidth: '10%',
    flexBasis: ["25px", "20px", null, "25px"],
    maxWidth: ["25px", "20px", null, "25px"],
  }),
  itinerary__item__view__detail: {
    //flex: '1 0 90%',
    flex: "1 0 0%",
    paddingLeft: "10px",
    maxWidth: "90%",
    fontSize: "0.7rem",
    ".itinerary__item__view__detail__title": mq({
      color: variables.orangeColor,
      fontWeight: 700,
      marginTop: "0.3rem",
      marginBottom: "0.5rem",
      fontSize: ["18px", null, "20px"],
      paddingLeft: ["unset", "unset", "unset", null, null, "unset"],
      ".itinerary__item__view__detail__inner__title": {
        //marginLeft : '0.5rem',
        //color : variables.fontColor
        //fontWeight : '400'
        display: "inline-block",
      },
    }),
  },
  itinerary__item__view__detail__small: {
    //flex: '1 0 90%',
    flex: "1 0 0%",
    maxWidth: "90%",
    fontSize: "0.7rem",
    paddingLeft: "10px",
    //marginBottom: '0.5rem',
    cursor: "pointer",
    ".itinerary__item__view__detail__title": mq({
      color: variables.orangeColor,
      fontWeight: 700,
      marginTop: "0.3rem",
      marginBottom: "0.5rem",
      fontSize: ["15px", null, "17px"],
      "&.atdw.green": {
        color: "#009245",
      },
      "&.atdw.orange": {
        color: "#F15A24",
      },
      "&.atdw.blue": {
        color: "#0071BC",
      },
      // '&:hover' : {
      //     color : variables.r
      // }
    }),
  },
  itinerary__item__view__detail__des: mq({
    fontSize: ["1rem", null, "0.7rem"],
    "p": {
      marginBottom: "0.5rem",
    },
  }),

  itinerary__view__index__item: mq({
    position: "relative",
    height: ["25px", "20px", null, "25px"],
    width: ["25px", "20px", null, "25px"],
    backgroundColor: variables.orangeColor,
    borderRadius: "50%",
    marginTop: "4px",
    "&.green": {
      backgroundColor: "#009245",
    },
    "&.blue": {
      backgroundColor: "#0071BC",
    },
    "&.orange": {
      backgroundColor: "#F15A24",
    },
    ".itinerary__view__index__item__inner": mq({
      position: "absolute",
      //top: '50%',
      top: ["51%", null, "44%"],
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      lineHeight: "1",
    }),
  }),
  itinerary__view__index__item__small: {
    position: "relative",
    // height: '9px',
    // width: '9px',
    // backgroundColor: variables.orangeColor,
    // borderRadius: '50%',
    //marginTop: '4px',
    ".itinerary__view__index__item__inner__small": {
      position: "absolute",
      top: "12px",
      left: "8px",
      //color: 'white',
      //lineHeight: '1'
      height: "9px",
      width: "9px",
      backgroundColor: variables.orangeColor,
      borderRadius: "50%",
    },
  },
};
