import { mq } from '../../../cssInJs';

// export default{
//     label:{
//         fontWeight: 600
//     }
// }

export default {
    form : {
        '.form-group' : {
            marginBottom: '0.5rem'
        },
        '.form-control' : {
            backgroundColor: 'white',
            borderColor : '#534841'
        }
    },
    label:{
        fontWeight: 600,
        marginBottom: '0px'
    },
    formLast : mq({
        marginBottom: ['0.5rem',null,null,'0px !important']
    }),
    searchBtn : {
        marginTop: '1rem',
        fontWeight: 600
    },
    atdwTypeSelect : mq({
        height: ['27px !important',null,null,'196px !important'],
        marginTop: '0.15rem'
    }),
    eventButton:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
        '.form-group' : {
            marginBottom:'0.5rem !important'
        }
    }
}