import { css } from '@emotion/core';
import { variables, mq } from 'cssInJs';

export default{
    atdwMap : css({
        maxWidth:'960px',
        margin: 'auto',
        '.info__window' : {
            maxWidth: '300px'
        },
        '.info__title' : {
            fontWeight: '700',
            fontSize: '15px'
        },
        '.info__town' : {
            fontWeight: '300',
            fontSize: '13px',
            margin:'0.2rem 0px'
        }
    }),
    filters : css({
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        justifyContent:'center',
        marginTop: '0.5rem',
        marginBottom:'0.5rem',
        '.btn' : {
            lineHeight: '1',
            margin:'4px',
            fontSize:'0.6rem'
        },
        '.btn.selected' : {
            backgroundColor : variables.dkOrangeColor,
            borderColor: variables.dkOrangeColor
        }
    }),
    popupBackground : css({
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9999'
    }),
    container : css({
        position: 'fixed',
        width: '95%',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        marginTop: '-1rem',
        zIndex:'9999',
        backgroundColor:'white',
        padding: '1rem',
        maxWidth:'450px'
    }),
    popupTitle : css({
        fontWeight: '700',
        fontSize: '1rem'
    }),
    popupTown : css({
        fontWeight: '300',
        fontSize: '0.8rem',
        margin:'0.2rem 0px'
    }),
    popupDesc : css({
        fontSize:'0.8rem'
    }),
    button : css({
        fontWeight: '700'
    }),

    atdw__cellar__door__data : {
        overflow:'hidden',
        maxHeight:'0',
        transition: 'all 0.8s',
        padding: '0px',
        maxWidth:'960px',
        marginLeft: 'auto',
        marginRight: 'auto',
        '&.expand' : {
            maxHeight:'40000px',
            padding: '1rem 0'
        }
    },

    barBtnContainer : mq({
        display: 'flex',
        marginLeft: '-0.5rem',
        marginRight: '-0.5rem',
        marginTop:'0.3rem',
        flexWrap: 'wrap',
        justifyContent: ['center',null,'unset']
    }),
    barBtn : mq({
        lineHeight: '1',
        padding:['0.5rem 2rem',null,'0.3rem 2rem'],
        borderRadius: '13px',
        color: variables.orangeColor,
        backgroundColor: variables.sandCtoneColor,
        marginLeft:'0.5rem',
        marginRight:'0.5rem',
        fontSize: '0.8rem',
        marginTop: '0.2rem',
        marginBottom: '0.2rem',
        width: ['140px','unset']
        // flex: ['1 0 32%',null,'unset'],
        // maxWidth:['32%',null,'unset']
    }),
    
    productName : mq({
        color: variables.orangeColor,
        lineHeight: '1.1',
        fontSize: '2.5rem',
        //paddingLeft: '0.5rem',
        //paddingRight: '0.5rem'
    }),
    productDes : mq({
        '&.des__0' : {
            fontWeight: 600
        }
    }),
    productRate : {
        fontWeight: 600,
        marginTop:'1.5rem',
        //marginBottom: '1rem'
    },
    expendablePanel : {
        marginTop: '1rem',
        '&.open' : {
            '.exPanelTitle svg' : {
                transform:'translateY(-50%) rotate(180deg)'
            },
            '.expendablePanelContent' : {
                opacity: '1',
                //height: '100%'
                height: 'auto'
            }
        }
    },
    expendablePanelTitle : {
        borderBottom: `1px solid white`,
        cursor:'pointer',
        position:'relative',
        'svg' : {
            position:'absolute',
            right:'0px',
            top:'50%',
            transform: 'translateY(-50%)',
            transition: 'all 0.2s ease-in-out'
        }
    },
    expendablePanelContent : {
        transition: 'opacity 1s ease-out !important',
        opacity: '0',
        height: '0',
        overflow:'hidden'
    },
    expanleTitle : {
        fontWeight: 600,
    },
    content : {
        fontSize: '0.9rem'
    },
    searchFunctionText : {
        fontWeight: 600,
        marginTop:'3rem',
        marginBottom: '1rem'
    },
    searchTextTitle : {
        fontWeight: 600,
        fontSize: '2.2rem',
        textTransform: 'uppercase',
        color: variables.orangeColor,
        marginBottom: '1.5rem'
    }
}