/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./atdwTileStyle";
import { SiteLink } from "../../../components";
import utils from "../../../utils";

export function AtdwTile(props) {
  const product = props;
  const useSlider = props.useSlider;
  let firstDate = "";

  if (
    product.additionalData &&
    product.additionalData.eventFrequency &&
    product.additionalData.eventFrequency.length > 0 &&
    product.productType === "EVENT"
  ) {
    const dateArray =
      product.additionalData.eventFrequency[0].frequencyStartDate.split("-");
    firstDate = dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }

  return (
    <div
      css={style.atdwTile}
      id={product.productId}
      className={`${useSlider && "useSlider"}`}
    >
      <SiteLink to={"/atdwproduct/" + product.slug}>
        
        <div className="atdwitembg__container" css = {style.atdwitembg__container}>
          <div
            className="atdwitembg"
            css={style.bg}
            style={{ backgroundImage: utils.css.bgUrlStyle(product.imageUrl) }}
          ></div>
        </div>

        <div css={style.container}>
          <div css={style.details} className={firstDate !== "" ? "more" : ""}>
            {firstDate !== "" && <div css={style.eventDate}>{firstDate}</div>}
            <div css={style.pName}>{product.productName}</div>
            {/* <hr css={style.hr} /> */}
            <div css={style.des}>{product.shortDesc}</div>
          </div>
          {/* <div css={style.bottomImg}>
                        <img src="/assets/flow-device-transparent.png" style={{width:'100%'}} alt="wave"/>
                    </div> */}
        </div>
      </SiteLink>
    </div>
  );
}
